@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  .spinner {
    border: 4px solid rgba(255, 255, 255, 0.3);
    border-left-color: #070404;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 1s linear infinite;
  }

  .tooltip {
    position: relative;
    display: inline-block;
}

.tooltip .tooltiptext {
    visibility: hidden;
    width: 400px;
    background-color: black;
    color: white;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    margin-left: -200px; /* Center the tooltip */
    opacity: 0;
    transition: opacity 0.3s, visibility 0.3s;
    transform: translateX(-50%);
}

.tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
}
