.bordered-cell {
    border: 1px solid #f3eeee;
    padding: 8px;
}

* {
    font-family: 'Arial', sans-serif;
  }

.search-form {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 20px;
}
.search-input {
    flex: 1 1 180px; /* Adjust the flex-basis as per your layout needs */
}

.pagination button {

    background-color: #f8f8f8;
    border: 1px solid #ccc;
    cursor: pointer;
}

.pagination button:hover {
    background-color: #e2e2e2;
}

.pagination button.active {
    background-color: #007bff; /* Blue background for the active page */
    color: white; /* White text for the active page */
    border-color: #0056b3; /* Darker blue border for the active page */
}

.pagination button:disabled {
    background-color: #e9ecef;
    color: #6c757d;
    cursor: not-allowed;
}
