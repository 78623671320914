.address-container {
    display: flex;
    flex-direction: column;
    gap: 3px;
}

.address-container > div {
    display: flex;
    flex-direction: row;
    gap: 3px;
}

.deliveryaddress-box {
    border: 2px solid #ccc;
    padding: 10px;
    margin-top: 20px;
    background-color:darkslategrey;
}

.deliveryaddress-info {
    display: flex;
}

.deliveryaddress-info p {
    margin-right: 5px;
}

.crm-container {
    display: flex;
    justify-content: space-between;
}

.customer-table, .customer-settings {
    flex: 1;
    padding: 10px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
}

.customer-settings {
    margin-left: 20px; /* Spacing between the table and the settings */
}
